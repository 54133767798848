// export const API_SERVER_URL = "http://127.0.0.1:8080";
export const API_SERVER_URL = "https://api.opportunitybankhk.org";
export const IMAGE_BASE_URL = "https://api.opportunitybankhk.org/images";
export const API_SERVER_URL_DEV = "http://localhost:8080";
export const DEFAULT_JWT_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJPQl9BUFBfVEtOIiwiZW52IjoiUFJPRCIsImFwcCI6Ik9CX1BST0RfRU5WX0FETUlOX0FQUCIsImlhdCI6MTYxNDkyOTc5NCwiZXhwIjoxOTMwMjg5Nzk0fQ.NSJCKafSFJqJA7U-tbui9Nrpxbfh1U876Dinx8x8ZSc";
// export const DEFAULT_JWT_TOKEN =
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJPQl9BUFBfVEtOIiwiZW52IjoiVEVTVCIsImFwcCI6Ik9CX1RFU1RfRU5WX0FETUlOX0FQUCIsImlhdCI6MTYwNDQxNjc1NSwiZXhwIjoxOTE5Nzc2NzU1fQ.ezSU6V3LZa4K8QBwGxTa4d_poBLh5z5HStdxLZRVvNY";
export const DEFAULT_JWT_TOKEN_DEV =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJPQl9BUFBfVEtOIiwiZW52IjoiREVWIiwiYXBwIjoiT0JfREVWX0VOVl9BRE1JTl9BUFAiLCJpYXQiOjE2MDQ0MTY0MzEsImV4cCI6MTkxOTc3NjQzMX0.LOifccYxk0pJBiaIy0EK7_-9D1YAgnFfGUn_MX99tgI";
